import React, { FC } from 'react';
import { graphql } from 'gatsby';

import ProductComponent from 'layout/ProductComponent';

import { ProductPageProps } from './models';

const ProductPage: FC<ProductPageProps> = ({
  data: {
    productPage,
    productsListingPage: { purchaseLinkText },
  },
}) => <ProductComponent product={productPage} {...{ purchaseLinkText }} />;

export const query = graphql`
  query ($url: String) {
    productPage(url: { eq: $url }) {
      name
      body
      url
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      basicInfo {
        name
        image {
          altText
          childImageSharp {
            gatsbyImageData(width: 500, height: 500)
          }
        }
      }
      relatedProductShowMore
      relatedArticleLabel
      relatedArticle {
        title
        description
        cta {
          name
          url
        }
        image {
          altText
          fallbackUrl
        }
      }
      relatedProducts {
        title
        description
        cta {
          name
          url
        }
        image {
          altText
          fallbackUrl
        }
      }
      relatedProductsLabel
      purchaseLinks {
        buttonText
        buttonLink {
          url
        }
      }
    }
    productsListingPage {
      purchaseLinkText
    }
  }
`;

export default ProductPage;
