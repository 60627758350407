import React, { FC } from 'react';

import { IRelatedProductArticle } from './model';

import './relatedProductArticle.scss';

const RelatedProductArticle: FC<IRelatedProductArticle> = ({
  title,
  description,
  cta,
  image,
  textButton,
}) => (
  <div className="article__card">
    <img className="card__img" src={image?.fallbackUrl} alt={image?.altText ?? ''} />
    <div className="display">
      <h5 className="card__title">{title}</h5>
      {description ? <p>{description}</p> : null}
      <div className="btn__content">
        <a href={cta[0].url} className="btn__link btn">
          {textButton}
        </a>
      </div>
    </div>
  </div>
);

export default RelatedProductArticle;
