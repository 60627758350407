import React, { FC } from 'react';

import { Layout } from 'layout';
import PurchaseLinks from 'layout/PurchaseLinks';
import RelatedProductAndArticle from 'layout/RelatedProductAndArticles/RelatedProductAndArticles';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { ProductProps } from './models';

import './productComponent.scss';

const ProductComponent: FC<ProductProps> = ({
  purchaseLinkText,
  product: {
    body,
    basicInfo,
    purchaseLinks,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    relatedProductShowMore,
    relatedProducts,
    relatedProductsLabel,
    relatedArticle,
    relatedArticleLabel,
  },
}) => {
  const { image, name } = basicInfo[0];
  const purchaseLinksRender = purchaseLinks.map((purchase) => {
    const { buttonLink, buttonText } = purchase;

    return buttonLink ? <PurchaseLinks purchaseLinks={purchase} key={buttonText} /> : null;
  });

  return (
    <Layout>
      <Seo
        {...{ title: seoMetaTitle, description: seoMetaDescription, keywords: seoMetaKeywords }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <div className="content container product">
        <div className="content__row row">
          <div className="content__column--4 col--12 col-md-4">
            <h1 className="title__h1 my-4">{name}</h1>
            <div className="image__content">
              <Image imageData={image} alt={image.altText} />
            </div>
            <div className="store__content">
              <p className="pharagraph">{purchaseLinkText}</p>
              {purchaseLinksRender}
            </div>
          </div>
          <div className="content__column--8 col--12 col-md-8">
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </div>
        </div>
        <div className="container__related--article  mt-5">
          <h3 className="text-center my-5 related__title">{relatedArticleLabel ?? ''}</h3>
          <div className="container__articles">
            {relatedArticle && relatedArticle.length > 0
              ? relatedArticle.map((article) => (
                  <RelatedProductAndArticle {...article} textButton={relatedProductShowMore} />
                ))
              : null}
          </div>
        </div>
        <div className="container__related--product  mt-5">
          <h3 className="text-center my-5 related__title">{relatedProductsLabel ?? ''}</h3>
          <div className="container__articles">
            {relatedProducts && relatedProducts.length
              ? relatedProducts.map((product) => (
                  <RelatedProductAndArticle {...product} textButton={relatedProductShowMore} />
                ))
              : null}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ProductComponent;
