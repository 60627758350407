import React, { FC } from 'react';

import { PurchaseLinksProps } from './models';

const PurchaseLinks: FC<PurchaseLinksProps> = ({
  purchaseLinks: {
    buttonText,
    buttonLink: { url },
  },
}) => (
  <div className="btn__content" key={buttonText}>
    <a className="btn__link btn" href={url} target="_blank" rel="noreferrer">
      {buttonText}
    </a>
  </div>
);

export default PurchaseLinks;
